import { Page } from "../../components/Page";
import { Sheet } from "../../components/Sheet";
import IconFile from "../../../assets/file.svg";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/context";
import { config } from "../../../config";

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export const OwnerManuals = observer(() => {
  const { appStore } = useStores();

  return (
    <Page active="manuals">
      <Sheet title="" subtitle="Manuals">
        <div className="list-wrapper">
          {appStore.model.Owner_Manual && (
            <div className="warranty-items">
              <a
                className="warranty-item"
                href={`${config.api}/my-limitless/FieldAttachment/Models/${appStore.model.id}/${appStore.model.Owner_Manual[0].attachment_Id}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text">Owner Manual</div>
                <div className="icon">
                  <img src={IconFile} alt="" />
                </div>
              </a>
            </div>
          )}

          {appStore.model.Electrical_Manual && (
            <div className="warranty-items">
              <a
                className="warranty-item"
                href={`${config.api}/my-limitless/FieldAttachment/Models/${appStore.model.id}/${appStore.model.Electrical_Manual[0].attachment_Id}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text">Electrical Manual</div>
                <div className="icon">
                  <img src={IconFile} alt="" />
                </div>
              </a>
            </div>
          )}

          {appStore.model_attachments.sort(compare).map((x, i) => (
            <div className="warranty-items" key={i}>
              <a
                className="warranty-item"
                href={`${x.url}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text">{x.name}</div>
                <div className="icon">
                  <img src={IconFile} alt="" />
                </div>
              </a>
            </div>
          ))}
        </div>
      </Sheet>
    </Page>
  );
});
