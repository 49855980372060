import { Sidenav } from "./Sidenav";

export const Page = (props) => {
  return (
    <main className="dashboard">
      <div className="container">
        <div className="row">
          <div className="col-3 col-md-4 col-lg-3 hide-mobile">
            <Sidenav active={props.active} />
          </div>
          <div className="col-12 col-md-8 col-lg-9">{props.children}</div>
        </div>
      </div>
    </main>
  );
};
