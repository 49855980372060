import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Loading } from "../../components/Loading";
import { Page } from "../../components/Page";
import { Sheet } from "../../components/Sheet";
import { Locations } from "../../services/location.service";
import { useStores } from "../../stores/context";

export const Support = observer(() => {
  const { appStore } = useStores();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (e.target.reportValidity()) {
      // checkvalidity

      setLoading(true);

      await appStore.createServiceRequest();

      alert(
        "Your service request has been created. Our service specialist will contact you soon."
      );

      setLoading(false);

      appStore.fetch();
    }
  };

  if (loading) return <Loading />;

  return (
    <Page active="support.create">
      <Sheet title="After Sales &amp; Support" subtitle="Schedule Service">
        <div className="form-wrapper">
          <form className="form" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-item">
                  <label>Model</label>
                  <input type="text" disabled value={appStore.model.Name} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-item">
                  <label>Year</label>
                  <input
                    type="text"
                    disabled
                    value={appStore.yacht.Model_Year}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-item">
                  <label>Configuration ID</label>
                  <input
                    type="text"
                    value={appStore.configuration.Name}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-item">
                  <label>Service Request*</label>
                  <select
                    required
                    onChange={(e) =>
                      (appStore.serviceRequestForm.Service_Type =
                        e.target.value)
                    }
                  >
                    <option value="" disabled selected>
                      Select Service Request Type
                    </option>
                    <option value="Repair">Repair</option>
                    <option value="Addition">Addition</option>
                    <option value="Demand">Demand</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-item">
                  <label>Service Location*</label>
                  <select
                    required
                    onChange={(e) =>
                      (appStore.serviceRequestForm.Location = e.target.value)
                    }
                  >
                    <option value="" disabled selected>
                      Select Location
                    </option>

                    {Locations.map((l, i) => (
                      <option value={l} key={i}>
                        {l}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className="col">
                <div className="form-item">
                  <label>Service Date</label>
                  <input type="date" />
                </div>
              </div> */}
            </div>

            <div className="row">
              <div className="col">
                <div className="form-item">
                  <label>Request Title*</label>
                  <input
                    required
                    type="text"
                    onChange={(e) =>
                      (appStore.serviceRequestForm.Name = e.target.value)
                    }
                    value={appStore.serviceRequestForm.Name}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-item">
                  <label>Request Details*</label>
                  <textarea
                    required
                    cols="30"
                    rows="10"
                    onChange={(e) =>
                      (appStore.serviceRequestForm.Description = e.target.value)
                    }
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button type="submit" className="button-dark">
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </Sheet>
    </Page>
  );
});
