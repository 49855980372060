import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import Protected from "./Protected";
import { Index } from "./routes";
import { AboutGeneral } from "./routes/about/general";
import { AboutWarranty } from "./routes/about/warranty";
import { Complete } from "./routes/account/complete";
import { CompletePassword } from "./routes/account/completePassword";
import { Login } from "./routes/account/login";
import { OwnerManuals } from "./routes/owner-manuals";
import { Priviliges } from "./routes/priviliges";
import { Support } from "./routes/support";
import { SupportList } from "./routes/support/list";
import ApiService from "./services/api.service";

export const Router = () => {
  const apiService = new ApiService();
  const [loading, setLoading] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const init = async () => {
    const result = await apiService.checkUser();

    setIsLoggedIn(result);
    setLoading(false);
  };

  React.useEffect(() => {
    init();
  });

  if (loading) return <div>Loading</div>;

  return (
    <Routes>
      <Route path="/account/complete/:token" element={<Complete />}></Route>
      <Route
        path="/account/forgot-password/:token"
        element={<CompletePassword />}
      ></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route
        path="/"
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <App />
          </Protected>
        }
      >
        <Route path="" element={<Index />} />
        <Route path="/owner-manuals" element={<OwnerManuals />} />
        <Route path="/support" element={<SupportList />} />
        <Route path="/support/create" element={<Support />} />
        <Route path="/about/general" element={<AboutGeneral />} />
        <Route path="/about/warranty" element={<AboutWarranty />} />
        <Route path="/priviliges" element={<Priviliges />} />
      </Route>
    </Routes>
  );
};
