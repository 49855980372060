export const Sheet = (props) => {
  return (
    <div className="sheet">
      <div className="page-header">
        <div className="title-wrapper">
          <div className="title-sub">{props.title}</div>
          <div className="title">{props.subtitle}</div>
        </div>

        {props.buttons && <div className="button-wrapper">{props.buttons}</div>}
      </div>

      {props.children}
    </div>
  );
};
