import { Page } from "../../components/Page";
import { Sheet } from "../../components/Sheet";
import { observer } from "mobx-react-lite";

export const Priviliges = observer(() => {
  return (
    <Page active="priviliges.index">
      <Sheet title="" subtitle="Priviliges">
        <div>Under construction</div>
      </Sheet>
    </Page>
  );
});
