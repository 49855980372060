import { makeAutoObservable } from "mobx";
import ApiService from "../services/api.service";

export default class AppStore {
  loading = true;
  not_found = false;
  yacht = {};
  configuration = {};
  contact = {};
  model = {};
  serviceRequests = [];
  docs = {
    "Limitless Brochure": undefined,
    "XLV Brochure": undefined,
    "LIV Brochure": undefined,
    "LXV Brochure": undefined,
    "User Guide": undefined,
  };

  serviceRequestForm = {
    Name: "",
    Description: "",
    Service_Type: "",
    Location: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetch() {
    const apiService = new ApiService();

    try {
      const result = await apiService.getYacht();

      this.yacht = result.yacht;
      this.configuration = result.configuration;
      this.contact = result.contact;
      this.model = result.model;
      this.serviceRequests = result.serviceRequests;
      this.yacht_attachments = result.yacht_attachments;
      this.model_attachments = result.model_attachments;

      result.docs.forEach((el) => {
        if (el.File) {
          if (el.Type === "Limitless Brochure") {
            this.docs["Limitless Brochure"] = {
              docs_id: el.id,
              attachment_Id: el.File[0].attachment_Id,
            };
          }
          if (el.Type === "XLV Brochure") {
            this.docs["XLV Brochure"] = {
              docs_id: el.id,
              attachment_Id: el.File[0].attachment_Id,
            };
          }
          if (el.Type === "LIV Brochure") {
            this.docs["LIV Brochure"] = {
              docs_id: el.id,
              attachment_Id: el.File[0].attachment_Id,
            };
          }
          if (el.Type === "LXV Brochure") {
            this.docs["LXV Brochure"] = {
              docs_id: el.id,
              attachment_Id: el.File[0].attachment_Id,
            };
          }
          if (el.Type === "User Guide") {
            this.docs["User Guide"] = {
              docs_id: el.id,
              attachment_Id: el.File[0].attachment_Id,
            };
          }
        }
      });

      this.loading = false;
    } catch (e) {
      this.not_found = true;
      this.loading = false;
    }
  }

  async getServiceRequests() {
    const apiService = new ApiService();

    const result = await apiService.getServiceRequests();

    this.serviceRequests = result;

    this.loading = false;
  }

  async createServiceRequest() {
    const apiService = new ApiService();

    const result = await apiService.createServiceRequest({
      Name: this.serviceRequestForm.Name,
      Description: this.serviceRequestForm.Description,
      Service_Type: this.serviceRequestForm.Service_Type,
      Location: this.serviceRequestForm.Location,
      Select_Boat: this.yacht.id,
    });

    return result;
  }

  async logout() {
    localStorage.removeItem("mylimitless:user");
    window.location.reload();
  }
}
