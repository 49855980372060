import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import mail from "../../assets/mail.svg";
import { useStores } from "../stores/context";

export const Index = observer(() => {
  const { appStore } = useStores();

  return (
    <main
      className="dashboard"
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
    >
      <div style={{ flex: 1, display: "flex" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="ba-card">
                <div
                  className="box"
                  style={{
                    backgroundImage:
                      "url(https://my.limitlessseas.com/uploads/limitless_xlv.jpg)",
                  }}
                >
                  <div style={{ padding: 25 }}>
                    <div className="welcome">
                      Hello {appStore.contact.Full_Name}
                    </div>
                    <div className="yacht-logo">
                      <div className="row">
                        <div className="col-12">
                          <span>{appStore.yacht.Model.name}</span>
                        </div>
                        <div className="col-12">
                          <span className="conf-id">
                            Configuration ID:{" "}
                            {appStore.yacht.Configuration_Information.name}
                          </span>
                        </div>
                        <div className="col-12">
                          <span className="conf-id">
                            HIN: {appStore.yacht.Limitless_Hull_Codes}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="button-row">
                        <Link to="/support" className="button-white">
                          My Service Records
                        </Link>
                        <Link to="/support/create" className="button-white">
                          Schedule Service
                        </Link>
                      </div>
                      <div className="button-row">
                        <a
                          href="mailto:sales@limitlessseas.com"
                          className="button-dark narrow"
                        >
                          <img src={mail} alt="" />
                        </a>

                        <a
                          href="tel:+19545914328"
                          className="button-dark narrow"
                          style={{ marginLeft: 10 }}
                        >
                          CALL
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ flex: 2, display: "flex" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="ba-card">
                <Link
                  to="/about/general"
                  className="box hover"
                  style={{
                    backgroundImage:
                      "url(https://my.limitlessseas.com/uploads/about_my_limitless.jpg)",
                  }}
                >
                  <div className="title">
                    About
                    <br />
                    My Limitless
                  </div>
                </Link>
                <Link
                  to="/support"
                  className="box hover"
                  style={{
                    backgroundImage:
                      "url(https://my.limitlessseas.com/uploads/after_sales.jpg)",
                  }}
                >
                  <div className="title">
                    After Sales &amp;
                    <br />
                    Support
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="ba-card">
                <a
                  // href={appStore.configuration.Configuration_URL}
                  href="/"
                  onClick={(e) => e.preventDefault()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="box hover"
                  style={{
                    filter: "grayscale(1)",
                    backgroundImage:
                      "url(https://my.limitlessseas.com/uploads/upgrade_my_limitless.jpg)",
                  }}
                >
                  <div className="title">
                    Upgrade
                    <br />
                    My Limitless
                  </div>
                </a>
              </div>
            </div>{" "}
            <div className="col-12 col-lg-4">
              <div className="ba-card">
                <a
                  href="https://limitlessseas.com/boutique"
                  // href="/"
                  // onClick={(e) => e.preventDefault()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="box hover"
                  style={{
                    // filter: "grayscale(1)",
                    backgroundImage:
                      "url(https://my.limitlessseas.com/uploads/boutique.jpg)",
                  }}
                >
                  <div className="title">Boutique</div>
                </a>
                <a
                  // to="/priviliges"
                  href="/"
                  onClick={(e) => e.preventDefault()}
                  className="box hover"
                  style={{
                    filter: "grayscale(1)",
                    backgroundImage:
                      "url(https://my.limitlessseas.com/uploads/privileges.jpg)",
                  }}
                >
                  <div className="title">Privileges</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
});
