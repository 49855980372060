import { observer } from "mobx-react-lite";
import { config } from "../../config";
import { useStores } from "../stores/context";

export const Bookmark = observer((props) => {
  const { appStore } = useStores();

  return (
    <div className="bookmarks">
      <div className="container">
        <div className="row">
          {/* {appStore.yacht.Warranty_Certificate_PDF && (
            <div className="col">
              <a
                href={`${config.api}/my-limitless/FieldAttachment/Yachts/${appStore.yacht.id}/${appStore.yacht.Warranty_Certificate_PDF[0].attachment_Id}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Warranty Certificate
              </a>
            </div>
          )} */}
          {appStore.docs["User Guide"] && (
            <div className="col">
              <a
                href={`${config.api}/my-limitless/FieldAttachment/Docs/${appStore.docs["User Guide"].docs_id}/${appStore.docs["User Guide"].attachment_Id}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                User Guide
              </a>
            </div>
          )}
          <div className="col">
            <a
              href={appStore.configuration.Configuration_PDF}
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              Configuration PDF
            </a>
          </div>
          {appStore.docs["Limitless Brochure"] && (
            <div className="col">
              <a
                href={`${config.api}/my-limitless/FieldAttachment/Docs/${appStore.docs["Limitless Brochure"].docs_id}/${appStore.docs["Limitless Brochure"].attachment_Id}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Limitless Seas Brochure
              </a>
            </div>
          )}

          {appStore.docs["XLV Brochure"] && (
            <div className="col">
              <a
                href={`${config.api}/my-limitless/FieldAttachment/Docs/${appStore.docs["XLV Brochure"].docs_id}/${appStore.docs["XLV Brochure"].attachment_Id}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                XLV Brochure
              </a>
            </div>
          )}
          {appStore.docs["LIV Brochure"] && (
            <div className="col">
              <a
                href={`${config.api}/my-limitless/FieldAttachment/Docs/${appStore.docs["LIV Brochure"].docs_id}/${appStore.docs["LIV Brochure"].attachment_Id}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                LIV Brochure
              </a>
            </div>
          )}
          {appStore.docs["LXV Brochure"] && (
            <div className="col">
              <a
                href={`${config.api}/my-limitless/FieldAttachment/Docs/${appStore.docs["LXV Brochure"].docs_id}/${appStore.docs["LXV Brochure"].attachment_Id}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                LXV Brochure
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
