import { Link } from "react-router-dom";
import { Page } from "../../components/Page";
import { Sheet } from "../../components/Sheet";
import { useEffect, useState } from "react";
import moment from "moment";
import ApiService from "../../services/api.service";

export const SupportList = () => {
  const apiService = new ApiService();
  const [active, setActive] = useState(-1);
  const [data, setData] = useState([]);

  const initialize = async () => {
    const result = await apiService.getServiceRequests();
    setData(result);
  };

  useEffect(() => {
    initialize();
  }, []);

  const onOpen = (i) => {
    setActive(i);
  };

  return (
    <Page active="support.list">
      <Sheet
        title="After Sales &amp; Support"
        subtitle="My Service Records"
        buttons={
          <Link to="/support/create" className="button-dark">
            Create Record
          </Link>
        }
      >
        <div className="list-wrapper">
          <div className="table-head hide-mobile">
            <div className="row">
              <div className="col-3">Service Request</div>
              <div className="col-3">Created At</div>
              <div className="col-3">Scheduled</div>
              <div className="col-3">Status</div>
            </div>
          </div>
          <div className="table-body">
            {data.map((s, i) => (
              <div
                className={`table-row ${active === i ? "active" : ""} ${
                  s.Description ? "" : "no-content"
                }`}
                key={i}
              >
                <div className="row-title" onClick={() => onOpen(i)}>
                  <div className="row service-row">
                    <div className="col-12 col-md-3">
                      <span className="service-title">{s.Name}</span>
                    </div>
                    <div className="col-12 col-md-3">
                      <span className="service-date">
                        {moment(s.Created_Time).format("MM.DD.YYYY")}
                      </span>
                    </div>
                    <div className="col-12 col-md-3">
                      <span className="service-date">
                        {s.Ticket_Due_Date
                          ? moment(s.Ticket_Due_Date).format("MM.DD.YYYY")
                          : ""}
                      </span>
                    </div>
                    <div className="col-12 col-md-3">
                      <span className="service-status status-waiting">
                        {s.Request_Stage}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row-content animate__animated ${
                    active === i ? "animate__fadeIn" : ""
                  }`}
                >
                  <div className="row-content-inner">{s.Description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Sheet>
    </Page>
  );
};
